import { gql } from '@apollo/client'

export const SERVER_TIME = gql`
  query ServerTime {
    serverTime
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(input: { token: $token }) {
      accessToken
    }
  }
`

export const LOGIN = gql`
  mutation Login($loginId: String!, $password: String!, $timestamp: Int!) {
    login(input: { loginId: $loginId, password: $password, timestamp: $timestamp }) {
      id
      loginId
      role
      status
      accessToken
    }
  }
`

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($id: ID!, $password: String!, $newPassword: String!) {
    updatePassword(
      input: {id: $id, oldPassword: $password, newPassword: $newPassword}
    ) {
      succed
      message
    }
  }
`

export const USER = gql`
  query User{
    user{
      id
      loginId
      name
      status
      role
      permission
      parentId
      nickname
      profile {
        paymentTransfer
        game
        market
        package
      }
    }
  }
`

export const GET_ACCOUNTS = gql`
  query GetAccounts($role: Role!, $parentId: ID, $status: Status){
    accounts(role: $role, parentId: $parentId, status: $status) {
      id
      loginId
      name
      status
      lock
      currency
      credit
      betCredit
      maxLoss
      outstanding
      balance
      downLine
      subaccount
      lastLogin {
        ip
        region
        loginTime
      }
    }
  }
`

export const CREATE_ACCOUNT = gql`
  mutation CraeteAccount(
    $loginId: String!
    $name: String!
    $password: String!
    $role: Role!
    $status: Status!
    $lock: Boolean!
    $currency: Currency!
    $credit: Float!
    $maxLoss: Float!
    $phone: String
    $email: String
    $profile: ProfileInput!
    $commissionSettings: CommissionSettingsInput!
  ) {
    createAccount(input: {
      loginId: $loginId
      name: $name
      password: $password
      role: $role
      status: $status
      lock: $lock
      currency: $currency
      credit: $credit
      maxLoss: $maxLoss
      phone: $phone
      email: $email
      profile: $profile
      commissionSettings: $commissionSettings
    })
  }
`

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount(
    $id: ID!
    $loginId: String!
    $password: String!
    $name: String!
    $status: Status!
    $role: Role!
    $lock: Boolean!
    $currency: Currency!
    $credit: Float!
    $maxLoss: Float!
    $phone: String
    $email: String
    $profile: ProfileInput!
    $commissionSettings: CommissionSettingsInput!
  ) {
    updateAccount(
      id: $id,
      input: {
        loginId: $loginId
        name: $name
        password: $password
        status: $status
        role: $role
        lock: $lock
        currency: $currency
        credit: $credit
        maxLoss: $maxLoss
        phone: $phone
        email: $email
        profile: $profile
        commissionSettings: $commissionSettings
      }
    )
  }
`

export const GET_ACCOUNT = gql`
  query Account($id: String!) {
    account(id: $id) {
      id
      loginId
      password
      wechat
      role
      status
      create_time
    }
  }
`

export const CREATE_SUB_ACCOUNT = gql`
  mutation CraeteSubAccount(
    $loginId: String!
    $name: String!
    $password: String!
    $status: Status!
    $lock: Boolean!
    $permission: Int!
  ) {
    createSubAccount(input: {
      loginId: $loginId
      name: $name
      password: $password
      status: $status
      lock: $lock
      permission: $permission
    })
  }
`

export const UPDATE_SUB_ACCOUNT = gql`
  mutation UpdateSubAccount(
    $id: ID!
    $loginId: String!
    $name: String!
    $status: Status!
    $lock: Boolean!
    $permission: Int!
  ) {
    updateSubAccount(
      id: $id,
      input: {
        loginId: $loginId
        name: $name
        password: ""
        status: $status
        lock: $lock
        permission: $permission
      }
    )
  }
`

export const GET_SUB_ACCOUNTS = gql`
  query GetSubAccounts($status: Status){
    subaccounts(status: $status) {
      id
      loginId
      name
      status
      lock
      lastLogin {
        ip
        region
        loginTime
      }
    }
  }
`

export const UPDATE_NICKNAME = gql`
  mutation UpdateNickname($input: String!) {
    updateNickname(input: $input)
  }
`

export const SET_PASSWORD = gql`
  mutation SetPassword($id: ID!, $input: String!) {
    setPassword(id: $id, input: $input)
  }
`

export const SET_STATUS = gql`
  mutation SetStatus($id: ID!, $input: Status!) {
    setStatus(id: $id, input: $input)
  }
`

export const SET_CREDIT = gql`
  mutation SetCredit($id: ID!, $input: Float!) {
    setCredit(id: $id, input: $input)
  }
`

export const SET_MAXLOSS = gql`
  mutation SetMaxLoss($id: ID!, $input: Float!) {
    setMaxLoss(id: $id, input: $input)
  }
`

export const LOCK_ACCOUNT = gql`
  mutation LockAccount($id: ID!) {
    lockAccount(id: $id)
  }
`

export const UNLOCK_ACCOUNT = gql`
  mutation UnlockAccount($id: ID!) {
    unlockAccount(id: $id)
  }
`

export const SET_CUTOFF_TIME = gql`
  mutation SetCutOffTime($market: String!, $date: String!, $openTime: Int!, $closeTime: Int!) {
    setCutOffTime(input: {
      market: $market
      date: $date
      openTime: $openTime
      closeTime: $closeTime
    }) {
      openTime
      closeTime
    }
  }
`

export const TRANSFER = gql`
  mutation Transfer($input: [TransferInput!]) {
    transfer(input: $input) {
      id
      to {
        id
      }
      amount
      status
      timestamp
    }
  }
`

export const GET_MARKETS = gql`
  query GetMakrets {
    markets {
      name
      country
    }
  }
`

export const GET_ACTIVATED_MARKETS = gql`
  query ActivatedMarkets {
    activatedMarkets
  }
`

export const GET_HOT_NUMBERS = gql`
  query GetHotNumbers($market: String!, $date: String) {
    lottery(market: $market, date: $date) {
      market
      date
      hotNumbers {
        market
        date
        type
        number
        value
      }
    }
  }
`

export const GET_HOT_NUMBER_REWARDS = gql`
  query GetHotNumberRewards($market: String!, $date: String) {
    lottery(market: $market, date: $date) {
      market
      date
      hotNumberRewards {
        market
        date
        type
        number
        value
      }
    }
  }
`

export const GET_ANNOUNCEMENTS = gql`
  query GetAnnouncements {
    announcements {
      title
      message
      timestamp
    }
  }
`

export const GET_ANNOUNCEMENT = gql`
  query GetAnnouncement($id: ID) {
    announcement(id: $id) {
      id,
      title
      message
      timestamp
    }
  }
`

export const UPLOAD_FILES = gql`
  mutation UploadFiles($files: [UploadFile!]!) {
    uploadWithPayload(payload: $files) {
      id
      name
      contentType
      rawURI
      previewURI
    }
  }
`

export const GET_CATEGORIES = gql`
  query GetCategories($parentId: String!) {
    categoriesByParentID(id: $parentId) {
      id
      name
      parent_id
      has_subclass
      status
      sort
    }
  }
`