import React from "react"
import { 
  styled,
  Box,
  Avatar,
  Typography,
} from "@mui/material"

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "265px"
}))

const BrandName = styled(Typography)(() => ({
  fontFamily: '"DM Sans", sans-serif !important',
  fontSize: '1.25rem !important',
  fontWeight: '500 !important',
  letterSpacing: '0.15rem !important',
  color: '#11142d',
}))

export default function Brand() {
  return (
    <Wrapper>
      <Avatar 
        alt={'We Agency'} 
        sx={{ width: 32, height: 32, marginRight: '0.5rem' }} 
        src={`/images/logo.png`}
      />
      <BrandName>WE AGENCY</BrandName>
    </Wrapper>
  )
}